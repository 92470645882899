import NextLink from 'next/link';
import { Flex, Link, Image } from '@chakra-ui/react';
import type { socialQuery } from '@app/interfaces/temporary';

export const SocialMedia = ({ redes }: SocialMediaProps): JSX.Element => {
  const socialMediaIcons = redes.map(({ icone, link, nome }) => {
    const isComplete = !!(icone?.url && nome && link);
    return isComplete ? (
      <Link
        filter="grayscale(100%)"
        opacity={0.8}
        transition="0.3s ease"
        as={NextLink}
        display="flex"
        alignItems="center"
        _hover={{
          filter: 'brightness(0) invert(1)',
          opacity: 1,
        }}
        key={nome}
        href={link}
      >
        <Image alt={nome} src={icone.url} height="20px" width="20px" />
      </Link>
    ) : (
      <></>
    );
  });

  return (
    <Flex w="full" pt="4rem" alignItems="center" justifyContent="space-between">
      <Image
        display={{ base: 'none', md: 'block' }}
        src="https://media.graphassets.com/RIzzKyMPTQuqiqGNwnX5"
        alt="Logo EQI"
        height="32px"
      />
      <Flex justifyContent="flex-start" gap="2rem">
        {socialMediaIcons}
      </Flex>
    </Flex>
  );
};

type SocialMediaProps = {
  redes: Exclude<socialQuery['redeSocial'], null | undefined>['redes'];
};
