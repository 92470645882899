import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { CreateAccountButton } from './CreateAccountButton';
import { Drawer } from './Drawer';

export const Header = (): JSX.Element => {
  const headerNavigation = useBreakpointValue({
    base: <Drawer />,
    lg: <CreateAccountButton />,
  });

  const { pathname } = useRouter();

  return (
    <Flex
      position="absolute"
      w="full"
      borderBottom="1px solid rgba(255, 255, 255, 0.1)"
      color="white"
      bg={pathname !== '/' ? 'green.300' : 'transparent'}
      justifyContent="space-between"
      px={{ base: 4, md: 16, xl: 20, '2xl': 64 }}
      py="44px"
    >
      <Link href="/" passHref>
        <Image
          src="https://media.graphassets.com/RIzzKyMPTQuqiqGNwnX5"
          alt="Logo EQI Research"
          width={176}
          height={32}
        />
      </Link>
      <Flex alignItems="center" gap="3rem">
        {headerNavigation}
      </Flex>
    </Flex>
  );
};
