import NextLink from 'next/link';
import { Text, Flex } from '@chakra-ui/react';
import { BsArrowRightShort } from 'react-icons/bs';

export const CreateAccountButton = () => (
  <NextLink
    href="https://cadastro.eqiresearch.com.br/?utm_campaign=AAAAMMDD-CPT-AON--X&utm_source=EQR-XXX-XXX&utm_medium=XXX-CT1-JK1&utm_content=RES-ACER&campaignId=7014V000002pf62QAA"
    target="_blank"
  >
    <Flex
      gap={4}
      alignItems="center"
      color="white"
      bgColor={{ base: 'green.300', lg: 'inherit' }}
      borderRadius="base"
      paddingX={{ base: 5, lg: 'inherit' }}
      paddingY={{ base: 5, lg: 'inherit' }}
      _hover={{ color: 'gray.30' }}
    >
      <Text fontSize="lg">Abra sua conta na EQI</Text>
      <BsArrowRightShort size="25px" />
    </Flex>
  </NextLink>
);
