import { Flex, Link, Text } from '@chakra-ui/react';
import { mockedFooter, mockedSocialMedia } from '@app/interfaces/temporary';
import { FooterLinks } from './FooterLinks';
import { SocialMedia } from './SocialMedia';

export const Footer = (): JSX.Element => {
  return (
    <Flex
      color="white"
      background="green.300"
      width="full"
      flexDir="column"
      p={{ base: '0 17px', md: '0 46px', xl: '0 8%', '2xl': '0 12.5%' }}
    >
      {mockedSocialMedia?.redeSocial?.redes && (
        <SocialMedia redes={mockedSocialMedia?.redeSocial?.redes} />
      )}
      <Text
        fontSize="10px"
        lineHeight="16px"
        fontWeight="400"
        mt="2.2rem"
        textAlign="justify"
      >
        A EQI/RESEARCH preza pela qualidade da informação e atesta a apuração de
        todo o conteúdo por sua equipe, ressaltando que os relatórios da
        EQI/RESEARCH não representam ofertas, negociação de valores mobiliários,
        ou outros instrumentos financeiros. As decisões de investimentos e
        estratégias financeiras devem ser realizadas pelos próprios leitores. A
        EQI/RESEARCH não se responsabiliza por perdas, danos (diretos, indiretos
        e incidentais), custos e lucros cessantes. Para maiores informações,
        veja a{' '}
        <Link
          href="https://www.in.gov.br/en/web/dou/-/resolucao-cvm-n-20-de-25-de-fevereiro-de-2021-305383862"
          target="_blank"
          textDecor="underline"
        >
          INSTRUÇÃO CVM N° 20, DE 25 DE FEVEREIRO DE 2021
        </Link>{' '}
        e também o{' '}
        <Link
          href="https://www.apimec.com.br/apimec-brasil/codigo-de-etica/"
          target="_blank"
          textDecor="underline"
        >
          Código de Ética da Apimec para o Analista de Valores Mobiliários.
        </Link>
      </Text>

      <FooterLinks userTermsLink={mockedFooter.rodape?.paginasRodape} />
    </Flex>
  );
};
