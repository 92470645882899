import NextLink from 'next/link';
import { Flex, Link } from '@chakra-ui/react';

export const FooterLinks = ({
  userTermsLink,
}: FooterLinksProps): JSX.Element => {
  const { link, nome } = userTermsLink;

  return (
    <Flex
      flexDir="column"
      pt="1.75rem"
      pb="4rem"
      textTransform="uppercase"
      fontSize="10px"
    >
      <Link as={NextLink} href={link} textDecor="underline">
        {nome}
      </Link>
    </Flex>
  );
};

type FooterLinksProps = {
  userTermsLink: { nome: string; link: string };
};
