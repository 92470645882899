import Image from 'next/image';
import { useRef } from 'react';
import {
  IconButton,
  useDisclosure,
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import { CgMenu, CgClose } from 'react-icons/cg';
import { CreateAccountButton } from '../CreateAccountButton';

export const Drawer = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  return (
    <>
      <IconButton
        aria-label="Menu Drawer"
        ref={btnRef}
        data-testid="open-drawer"
        variant="ghost"
        onClick={onOpen}
        icon={<CgMenu size="36px" />}
      />
      <ChakraDrawer
        data-testid="drawer"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="white.600">
          <DrawerHeader
            display="flex"
            justifyContent="center"
            paddingTop="6rem"
            paddingBottom="5rem"
          >
            <Image
              src="/images/eqi-research-logo-dark.svg"
              height={32}
              width={176}
              alt="Logomarca EQI"
            />
          </DrawerHeader>

          <DrawerBody
            display="flex"
            flexDirection="column"
            gap="5rem"
            alignItems="center"
          >
            <CreateAccountButton />
            <IconButton
              aria-label="Menu Drawer"
              variant="ghost"
              onClick={onClose}
              icon={<CgClose size="28px" />}
            />
          </DrawerBody>
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};
