export type rodapeQuery = {
  __typename?: 'Query';
  rodape?: {
    __typename?: 'Rodape';
    textoRodape?: string | null;
    logo?: {
      __typename?: 'Asset';
      url: string;
      width?: number | null;
      height?: number | null;
    } | null;
    boxRodape: Array<{
      __typename?: 'BoxRodape';
      titulo?: string | null;
      mensagem?: string | null;
      contentBox: Array<{
        __typename?: 'ItemsDoBox';
        textoDoItem?: string | null;
        tituloDoItem?: string | null;
        iconeDoItem?: {
          __typename?: 'Asset';
          url: string;
          width?: number | null;
          height?: number | null;
        } | null;
      }>;
    }>;
    paginasRodape: Array<{
      __typename?: 'PaginasRodape';
      nome?: string | null;
      link?: string | null;
    }>;
    paginasExternasRodape: Array<{
      __typename?: 'PaginasExternasRodape';
      nome?: string | null;
      link?: string | null;
    }>;
    selosRodape: Array<{
      __typename?: 'SelosRodape';
      nome?: string | null;
      imagem?: {
        __typename?: 'Asset';
        url: string;
        width?: number | null;
        height?: number | null;
      } | null;
    }>;
  } | null;
};

export type socialQuery = {
  __typename?: 'Query';
  redeSocial?: {
    __typename?: 'RedeSocial';
    redes: Array<{
      __typename?: 'Social';
      link?: string | null;
      nome?: string | null;
      icone?: {
        __typename?: 'Asset';
        url: string;
        width?: number | null;
        height?: number | null;
      } | null;
    }>;
  } | null;
};

export const mockedFooter = {
  rodape: {
    logo: {
      url: 'https://media.graphassets.com/nnNufhkT3uR93yjbM7nw',
      width: 0,
      height: 0,
      __typename: 'Asset',
    },
    boxRodape: [
      {
        titulo: 'Atendimento',
        mensagem: 'Segunda a sexta-feira (exceto feriados), das 9h às 18h',
        contentBox: [
          {
            iconeDoItem: null,
            textoDoItem: '4007 - 2374',
            tituloDoItem: 'Telefone',
            __typename: 'ItemsDoBox',
          },
          {
            iconeDoItem: {
              url: 'https://media.graphassets.com/gxWQLqDzRj2XO17wU4X7',
              width: 51,
              height: 50,
              __typename: 'Asset',
            },
            textoDoItem: '(47) 4007-2374',
            tituloDoItem: 'WhatsApp',
            __typename: 'ItemsDoBox',
          },
          {
            iconeDoItem: null,
            textoDoItem: 'atendimento@eqi.com.br',
            tituloDoItem: 'Email',
            __typename: 'ItemsDoBox',
          },
        ],
        __typename: 'BoxRodape',
      },
      {
        titulo: 'Ouvidoria',
        mensagem: 'Segunda a sexta-feira (exceto feriados), das 9h às 18h',
        contentBox: [
          {
            iconeDoItem: null,
            textoDoItem: '0800-770-0190',
            tituloDoItem: 'Telefone',
            __typename: 'ItemsDoBox',
          },
          {
            iconeDoItem: null,
            textoDoItem: 'ouvidoria@eqi.com.br',
            tituloDoItem: 'Email',
            __typename: 'ItemsDoBox',
          },
        ],
        __typename: 'BoxRodape',
      },
    ],
    paginasRodape: {
      nome: 'Termos de uso e Políticas de privacidade',
      link: 'https://eqi.com.br/politicas',
    },
    textoRodape:
      'Toda comunicação através da rede mundial de computadores está sujeita a interrupções ou atrasos, podendo prejudicar ordens, negociações ou a recepção de informações atualizadas.<br/><br/>\nA EQl INVESTIMENTOS, exime-se de responsabilidade por danos sofridos por seus clientes, por força de falha de serviços disponibilizados por terceiros, incluindo, sem limitação aqueles conexos à rede mundial de computadores.\n<br/><br/>\nA EQI Investimentos é uma empresa de agentes autônomos de investimentos contratada pelo BTG Pactual. Na forma de legislação da CVM, o agente autônomo de investimentos não pode administrar ou gerir patrimônio de investidores. O agente autônomo é um intermediário e depende da autorização prévia do cliente para realizar operações no mercado financeiro. O conteúdo apresentado no site não se trata de recomendação, indicação ou aconselhamento do investimento, sendo única e exclusiva responsabilidade do investidor a tomada de decisão. A rentabilidade obtida no passado não representa garantia de rentabilidade futura. Para informações e dúvidas, favor contatar seu assessor de investimentos.\n<br/><br/>\nToda transmissão de ordem por meio digital está sujeita a interrupções ou atrasos, podendo impedir ou prejudicar o envio de ordens ou a recepção de informações atualizadas.',
    __typename: 'Rodape',
  },
};

export const mockedSocialMedia: socialQuery = {
  redeSocial: {
    redes: [
      {
        icone: {
          url: 'https://media.graphassets.com/aQsPdGgITGWLv7JoPEBH',
          width: 0,
          height: 0,
          __typename: 'Asset',
        },
        link: 'https://www.instagram.com/eqiinvestimentos/',
        nome: 'Instagram',
        __typename: 'Social',
      },
      {
        icone: {
          url: 'https://media.graphassets.com/AStgCzp1TIuJ2zlv2hmQ',
          width: 0,
          height: 0,
          __typename: 'Asset',
        },
        link: 'https://pt-br.facebook.com/eqiinvestimentos/',
        nome: 'Facebook',
        __typename: 'Social',
      },
      {
        icone: {
          url: 'https://media.graphassets.com/h1Ug4XTSH6i4AAGAqnBF',
          width: 0,
          height: 0,
          __typename: 'Asset',
        },
        link: 'https://twitter.com/eqiinvest',
        nome: 'Twitter',
        __typename: 'Social',
      },
      {
        icone: {
          url: 'https://media.graphassets.com/68JpbrjOTcSOtIRRyLdW',
          width: 0,
          height: 0,
          __typename: 'Asset',
        },
        link: 'https://www.youtube.com/channel/UCK81Fr8TPbdsnpE_dImEPVg',
        nome: 'Youtube',
        __typename: 'Social',
      },
    ],
    __typename: 'RedeSocial',
  },
};
