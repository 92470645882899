import type { ReactNode } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { Footer } from '@app/components/Elements/Footer';
import { Header } from '@app/components/Elements/Header';

export const DefaultLayout = ({
  children,
}: DefaultLayoutProps): JSX.Element => {
  const imageSize = {
    h: 'clamp(744px, 100vh, 1024px)',
    w: 'full',
  };
  return (
    <Flex w="full" flexDir="column" zIndex={1}>
      <Header />
      <Box
        position="absolute"
        background="linear-gradient(90deg, #000 0.55%, rgba(0, 0, 0, 0.60) 56.03%, rgba(0, 0, 0, 0.00) 100%);green.650"
        zIndex={-1}
        {...imageSize}
      />
      <Image
        zIndex={-2}
        position="absolute"
        src="/images/business-woman-background.png"
        alt="Mulher de negócios utilizando um tablet"
        objectFit="cover"
        objectPosition={{ base: '70%', lg: 'center' }}
        {...imageSize}
      />
      <Flex mt="6.75rem" flexDir="column">
        {children}
      </Flex>
      <Footer />
    </Flex>
  );
};

type DefaultLayoutProps = {
  children: ReactNode;
};
